import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './adminProfile.scss';

const AdminProfile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [message, setMessage] = useState('');

  // Fetch admin profile on component mount
  const fetchAdminProfile = async () => {
    try {
      const response = await axios.get('https://manageapi.jetsonweb.com/api/admins/profile', {
        withCredentials: true,
      });
      console.log('Admin Profile:', response.data);
      setUserInfo(response.data); // Store the fetched data in userInfo
    } catch (error) {
      console.error('Error fetching admin profile:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    fetchAdminProfile();
  }, []); // Fetch profile on component mount

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
    }
  };

  // Handle form submission for updating profile
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userInfo?.full_name || !userInfo?.email) {
      setMessage('Full name and email are required.');
      return;
    }

    if (password && password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    const formData = new FormData();
    formData.append('full_name', userInfo?.full_name);
    formData.append('email', userInfo?.email);
    if (password) {
      formData.append('password', password);
    }
    if (profileImage) {
      formData.append('profile_image', profileImage);
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No session ID found. Please log in.');
        return;
      }

      await axios.put('https://manageapi.jetsonweb.com/api/admins/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
        withCredentials: true,
      });

      setMessage('Profile updated successfully!');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Error updating profile: ' + (error.response?.data?.message || 'Unknown error'));
    }
  };

  return (
    <div className="container">
      <h1>Admin Profile</h1>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">Profile Image:</label>
        <div className="imagePreview">
       

        </div>

      

        <label className="label">Full Name:</label>
        <input
          type="text"
          value={userInfo?.full_name || ''}
          onChange={(e) => setUserInfo({ ...userInfo, full_name: e.target.value })}
          placeholder="Enter your name"
          className="input"
        />

        <label className="label">Email:</label>
        <input
          type="email"
          value={userInfo?.email || ''}
          onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
          placeholder="admin@example.com"
          className="input"
        />

        <label className="label">New Password:</label>
        <input
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter new password"
          className="input"
          required
        />

        <label className="label">Confirm New Password:</label>
        <input
          type="text"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          className="input"
          required
        />

        <button type="submit" className="submitButton">Update Profile</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default AdminProfile;
