import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './comment.scss';

const Comment = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch comments from the API
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/comments');
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, []);

  // Update comment status
  const updateCommentStatus = async (commentId, newStatus) => {
    try {
      const response = await axios.put(`https://manageapi.jetsonweb.com/api/comments/${commentId}/status`, {
        status: newStatus,
      });
      console.log('Update Response:', response.data);

      // Update the comment status locally
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId ? { ...comment, status: response.data.status } : comment
        )
      );
    } catch (error) {
      console.error('Error updating comment status:', error.response?.data || error.message);
    }
  };

  return (
    <div className="comment-display-section">
      <h2>User Comments</h2>
      {loading ? (
        <p>Loading comments...</p>
      ) : comments.length > 0 ? (
        <div className="comment-list">
          {comments.map((comment) => (
            <div key={comment.id} className="comment-card">
              <h3>{comment.name}</h3>
              <p>{comment.description}</p>
              <span className="comment-date">{new Date(comment.created_at).toLocaleString()}</span>
              <div className="comment-status">
                <strong>Status:</strong> <span>{comment.status}</span>
              </div>
              <div className="comment-buttons">
                <button
                  onClick={() => updateCommentStatus(comment.id, 'posted')}
                  className="post-button"
                  disabled={comment.status === 'posted'} // Disable if already posted
                >
                  Post
                </button>
                <button
                  onClick={() => updateCommentStatus(comment.id, 'declined')}
                  className="decline-button"
                  disabled={comment.status === 'declined'} // Disable if already declined
                >
                  Decline
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-comments-message">No comments yet. Be the first to comment!</p>
      )}
    </div>
  );
};

export default Comment;
